const getEnv = (domains: RegExp) => !!window.location.host.match(domains)

export let isProd = false
export let isTest = false
export let isLocal = false

const prodDomains = /(www|quick-quote).bymiles.co.uk/
const testDomains = /(www|quick-quote).test.bymiles.co.uk/

export const env = (() => {
  if (getEnv(prodDomains)) {
    isProd = true
    return 'prod'
  } else if (getEnv(testDomains)) {
    isTest = true
    return 'test'
  } else {
    isLocal = true
    return 'local'
  }
})()

const getQuoteFlowAPI = () => {
  if (isProd) return 'https://checkout.bymiles.co.uk/prod'
  if (isTest) return 'https://checkout.test.bymiles.co.uk/test'
  if (isLocal) return window.location.origin + (process.env.REACT_APP_USE_LOCAL_API ? '/dev' : '/test')
}

const getCheckoutDomain = () => {
  if (isProd) return 'https://checkout.bymiles.co.uk'
  if (isTest) return 'https://checkout.test.bymiles.co.uk'
  return 'http://localhost:15012'
}

const getQuoteDomain = () => {
  if (isProd) return 'https://quote.bymiles.co.uk'
  return 'https://quote.test.bymiles.co.uk'
}

const getBrochureDomain = () => {
  if (isProd) return 'https://www.bymiles.co.uk'
  return 'https://test.bymiles.co.uk'
}

const getGtmConfig = () => {
  if (isLocal) {
    return {
      gtmAuth: 'wJTOslQBE0R3MFYbI5IpyQ',
      gtmPreviewEnv: '4',
    }
  }
  if (isTest) {
    return {
      gtmAuth: 'O3RIlttheK2c908iyap3kg',
      gtmPreviewEnv: '3',
    }
  }
  if (isProd) {
    return {
      gtmAuth: '1B33qcWBdMjSjKhfGkD9Lw',
      gtmPreviewEnv: '1',
    }
  }
}

const config = {
  isLocal,
  isProd,
  isTest,
  checkoutDomain: getCheckoutDomain(),
  quoteFlowAPI: getQuoteFlowAPI(),
  quoteDomain: getQuoteDomain(),
  brochureDomain: getBrochureDomain(),
  strippedDomain: document.location.hostname.replace(/quick-quote/, ''),
  gtmConfig: getGtmConfig(),
}

export default config
