import React, { Suspense, useContext } from 'react'
import { GetMOT } from '../components/MOT/GetMOT'
import { ShowMOT } from '../components/MOT/ShowMOT'
import { IconLoading, ButtonPrevious } from '@by-miles/bymiles-styles'
import { ProviderContext } from '../Provider'
import { WidgetStyle } from '../components/WidgetStyle'
import { WidgetFooter } from '../components/WidgetFooter'

const MOT = () => {
  const {
    state: { step, MOTStep },
    updateState,
  } = useContext(ProviderContext)

  return (
    <WidgetStyle step={step}>
      <Suspense
        fallback={
          <div className="page-loader">
            <IconLoading />
          </div>
        }
      >
        {MOTStep === 0 && <GetMOT />}
        {MOTStep === 1 && <ShowMOT />}
      </Suspense>

      <WidgetFooter />

      {MOTStep === 1 && (
        <ButtonPrevious
          className="widget-previous-button"
          label="Change vehicle"
          onClick={() => {
            updateState({ stateName: 'MOTStep', value: MOTStep - 1 })
          }}
        />
      )}
    </WidgetStyle>
  )
}

export default MOT
