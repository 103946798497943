import { useContext, useState } from 'react'
import { ProviderContext } from '../Provider'
import { H1, H4, ParagraphLead, Input, ButtonPrimary } from '@by-miles/bymiles-styles'
import config from '../config'
export const MagazineWidget = () => {
  const { state } = useContext(ProviderContext)
  const [reg, setReg] = useState('')
  const { urlParams } = state
  const targetURL = urlParams.targetURL || config.brochureDomain
  const viewClass = urlParams.viewClass || ''
  const handleRegSubmit = () => {
    const dataLayer = window.dataLayer || []
    dataLayer.push({
      event: 'page interaction',
      interaction_name: 'reg number - quick quote everywhere',
      button_name: 'submit',
    })
    window.open(`${targetURL}/quick-quote?reg=${reg}`, '_blank')
  }
  return (
    <div className={`magazine-widget ${viewClass}`}>
      <H1>
        We've won Car Insurance Provider of the Year <span className="behind">3 years</span>
        <span className="behind">in a row</span>.
      </H1>
      <H4>
        We've won Car Insurance Provider of the Year <span className="behind">3 years in a row</span>.
      </H4>
      <ParagraphLead className="lead">Get quoted in a couple of clicks.</ParagraphLead>
      <div className="input-button-wrapper">
        <Input
          name="enter-reg"
          onChange={value => setReg(value)}
          className="enter-reg"
          regNumFont
          placeHolder="Enter Reg"
        />
        <div className="desktop-button">
          <ButtonPrimary
            onClick={() => handleRegSubmit()}
            className="quote-me"
            label="Quote me"
            iconRight="IconArrowRight"
          />
        </div>
        <div className="mobile-button">
          <ButtonPrimary
            onClick={() => handleRegSubmit()}
            className="quote-me"
            label="Quote me"
            iconRight="IconCaretRight"
          />
        </div>
      </div>
    </div>
  )
}
