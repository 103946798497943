import React, { useContext, useEffect } from 'react'
import { ButtonPrimary, H3, H4, QuestionMark, InformationBox, Added, Alert, Duration } from '@by-miles/bymiles-styles'
import { ShowMOTStyle } from './ShowMOTStyle'
import moment from 'moment'
import { ProviderContext } from '../../Provider'
import { useNavigate } from 'react-router-dom'
import { DataLayer } from '@by-miles/data-layer'

export const ShowMOT = () => {
  const {
    state: {
      data: { estimated_mileage },
      vehicle,
      mot: { taxExpiry, motExpiry, taxStatus },
      analyticsEnabled,
    },
  } = useContext(ProviderContext)

  useEffect(() => {
    DataLayer.customEvent({
      eventName: 'mot widget - view mot results',
      eventType: 'virtual page view',
      section: 'mot-widget',
    })
  }, [])

  const navigate = useNavigate()

  const hasExpired = (expiry: string) => {
    const dateUnix = new Date(expiry).getTime()
    return dateUnix < Date.now()
  }

  return (
    <ShowMOTStyle>
      <div className="show-mot-container">
        <H3>
          The results are in for your <br /> {vehicle?.bymiles_make} {vehicle?.bymiles_model}.
        </H3>
        <div className="info-container">
          {motExpiry ? (
            <InformationBox
              className="info-box"
              icon={hasExpired(motExpiry) ? <Alert /> : <Added fill="white" stroke="#007668" />}
              primaryText={hasExpired(motExpiry) ? 'No MOT.' : 'MOT passed.'}
              secondaryText={
                <p className="expiry">
                  <span className="status">{hasExpired(motExpiry) ? 'Expired ' : 'Expires '}</span>
                  {moment(motExpiry).format('MMM DD YYYY')}
                </p>
              }
              background={hasExpired(motExpiry) ? 'amber' : 'mint-dark'}
            />
          ) : (
            <InformationBox
              className="info-box"
              icon={<QuestionMark fill="#320B42" border="#320B42" stroke="white" />}
              primaryText="MOT data unavailable."
              background="tint-aubergine"
            />
          )}
          {taxStatus === 'SORN' ? (
            <InformationBox
              className="info-box"
              icon={<Added fill="white" stroke="#007668" />}
              primaryText="SORN."
              secondaryText={<p className="expiry">You do not need to tax your car.</p>}
              background="mint-dark"
            />
          ) : (
            <>
              {taxExpiry ? (
                <InformationBox
                  className="info-box"
                  icon={hasExpired(taxExpiry) ? <Alert /> : <Added fill="white" stroke="#007668" />}
                  primaryText={hasExpired(taxExpiry) ? 'Not taxed.' : 'Taxed.'}
                  secondaryText={
                    <p className="expiry">
                      <span className="status">{hasExpired(taxExpiry) ? 'Expired ' : 'Expires '}</span>
                      {moment(taxExpiry).format('MMM DD YYYY')}
                    </p>
                  }
                  background={hasExpired(taxExpiry) ? 'amber' : 'mint-dark'}
                />
              ) : (
                <InformationBox
                  className="info-box"
                  icon={<QuestionMark fill="#320B42" border="#320B42" stroke="white" />}
                  primaryText="Tax data unavailable."
                  background="tint-aubergine"
                />
              )}
            </>
          )}
        </div>
        {estimated_mileage || estimated_mileage === 0 ? (
          <div className="instuctions-container">
            {estimated_mileage < 5000 ? (
              <div className="text-container">
                <H4>Your car only did {estimated_mileage} miles last year.</H4>
                <p className="description">
                  Based on that mileage, you might well be better off with pay-by-mile car insurance.
                </p>
              </div>
            ) : (
              <div className="text-container">
                <H4>Your car did {estimated_mileage} miles last year.</H4>
                <p className="description">
                  Answer just five more questions to see if you could save with pay-by-mile car insurance.
                </p>
              </div>
            )}
          </div>
        ) : (
          <div className="instuctions-container">
            <div className="text-container">
              <H4>Insure your {vehicle?.bymiles_make} by the mile.</H4>
              <p className="description">
                Answer just five more questions to see if you could save with pay-by-mile car insurance.
              </p>
            </div>
          </div>
        )}
        <ButtonPrimary
          label="Get a quick quote"
          iconRight="IconArrowRight"
          onClick={() => {
            DataLayer.customEvent({
              eventName: 'mot widget - progress with quick quote',
              eventType: 'form tracking',
              section: 'mot-widget',
            })
            navigate({
              pathname: '/',
              search: `${analyticsEnabled ? '?analytics=on' : ''}`,
            })
          }}
        />
        <div className="time-container">
          <div>
            <Duration />
          </div>
          <p className="time-info">Takes under a minute.</p>
        </div>
      </div>
    </ShowMOTStyle>
  )
}
