import { getApiKey } from './getAPIKey'
import config from '../config'

export const quickQuoteAPI = (url: string, init?: Pick<RequestInit, 'method' | 'body' | 'headers'>) => {
  return fetch(`${config.quoteFlowAPI}${url}`, {
    ...init,
    method: init?.method || 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'x-api-key': getApiKey(),
      ...init?.headers,
    },
    credentials: 'include',
    body: JSON.stringify(init?.body),
  })
}
