export const previousButtonText = [
  { step: 1, value: 'Change vehicle' },
  { step: 2, value: 'Change mileage' },
  { step: 3, value: 'No Claims Discount' },
  { step: 4, value: 'No Claims Discount' },
  { step: 5, value: 'Age' },
  { step: 6, value: 'Postcode' },
  { step: 7, value: 'Change my details' },
]

export const getUrl = (urlParams: any) => {
  return `?sid=quick-quote
  ${urlParams.promo ? `&promo=${urlParams.promo}` : ``}
  ${
    urlParams.partner
      ? `
  &ppp=${urlParams.partner}
  &ots=${urlParams.partner}
  &ref=${urlParams.partner}
  &pap=${urlParams.partner}
  &utm_source=${urlParams.partner}
  `
      : ``
  }
  &dtl=widget&&utm_medium=partnership&utm_campaign=widget`
}

export const getPreviousButtonText = (MOTStep: number, step: number, isMOTFlow: boolean) => {
  if (MOTStep === 1 && step === 1 && isMOTFlow) {
    return 'MOT status'
  } else if (previousButtonText[step - 1]) {
    return previousButtonText[step - 1].value
  } else {
    return 'Go Back'
  }
}
