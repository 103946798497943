import React, { useContext, useEffect } from 'react'
import styled from 'styled-components'
import { RegForm, DynamicTitle } from '@by-miles/quick-quote'
import { ProviderContext } from '../../Provider'

const GetMOTStyle = styled.div`
  border-radius: 8px;
  padding: 34px;
  height: 100%;
  max-width: 450px;
  text-align: center;
  position: relative;
  margin: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .get-mot-description {
    font-size: 16px;
  }

  .reg-number {
    margin: 24px 0;
  }
`

export const GetMOT = () => {
  const {
    state: {
      data: { reg },
      regErrorMessage,
      fetching,
    },
    handleRegSubmit,
    updateDataState,
    updateState,
  } = useContext(ProviderContext)

  useEffect(() => {
    updateState({ stateName: 'isMOTFlow', value: true })
  }, [updateState])

  const regFormProps = {
    reg,
    regErrorMessage,
    fetching,
  }

  return (
    <GetMOTStyle>
      <DynamicTitle
        title="Check the MOT status of your car."
        description={
          <p className="get-mot-description">
            We’ll tell you when your next MOT is due and when you’ll need to renew your road tax.
          </p>
        }
        hero={true}
      />
      <RegForm regFormProps={regFormProps} handleRegSubmit={handleRegSubmit} updateDataState={updateDataState} />
    </GetMOTStyle>
  )
}
