import styled from 'styled-components'
import { theme } from '@by-miles/bymiles-styles'

export const ShowMOTStyle = styled.div`
  border-radius: 8px;
  padding-top: 34px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;

  .show-mot-container {
    max-width: 450px;
    margin: 20px;
    color: ${theme.primaryColours.aubergine.main};

    .info-container {
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: start;
      margin: 24px 0px;

      .expiry {
        font-size: 14px;
      }

      .status {
        font-family: BM-Biotif;
        font-size: 12px;
        text-transform: uppercase;
      }

      .info-box {
        &:last-child {
          margin-top: 16px;
        }
      }
    }

    .time-container {
      margin: 8px 0px;
      display: flex;
      justify-content: center;
      align-items: center;

      .time-info {
        margin-left: 4px;
        font-size: 14px;
        color: ${theme.primaryColours.slateGray.darkSlateGray};
      }
    }
  
  .instuctions-container {
    display: flex;
    justify-content: center;

    .text-container {
      max-width: ${theme.grid.resolution.mobile.container}px;
    }

    .description {
      margin: 16px 0;
    }
  }

  .secondary-container {
    margin-top: 10px;
  }
`
