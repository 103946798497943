import React, { Suspense, useContext } from 'react'
import { ButtonPrevious, IconLoading } from '@by-miles/bymiles-styles'
import { FindReg, Mileage, NCD, Age, Postcode, RenewalMonth, Quote, Decline } from '@by-miles/quick-quote'
import { WidgetStyle } from '../components/WidgetStyle'
import { ProviderContext } from '../Provider'
import { getPreviousButtonText } from '../utils/widgetHelpers'
import { WidgetFooter } from '../components/WidgetFooter'
import config from '../config'
import { useNavigate } from 'react-router'

const Widget = () => {
  const {
    state,
    updateState,
    handleShowModal,
    handleRegSubmit,
    updateDataState,
    updateMileage,
    updatePostcode,
    handleQuickQuoteSubmission,
    handleRegEmailOnDecline,
    calculateAnnualEstimate,
    handleSendRenewalEmail,
  } = useContext(ProviderContext)

  const navigate = useNavigate()

  const {
    data: { reg, estimated_mileage, ncd, other_vehicle_relationship, other_vehicle_ncd, ages, postcode, renewal_month },
    pricing: { annualEstimate, pm_rate, sub_rate },
    vehicle,
    regErrorMessage,
    updateMileage: stateUpdateMileage,
    fetching,
    NCDStep,
    step,
    multipleAges,
    postcodeValid,
    declinePlus,
    letMeKnowSent,
    quoteId,
    showModal,
    activeModal,
    renewalReminderEmailRegistered,
    urlParams: { promo, partner },
    promo: { amount: promoAmount },
    isMOTFlow,
  } = state

  const regFormProps = {
    reg,
    regErrorMessage,
    fetching,
  }

  const mileageProps = {
    estimated_mileage,
    annualEstimate,
    step,
    updateState,
    stateUpdateMileage,
    updateMileage,
    vehicle,
    isMOTFlow,
    imageSrc: 'four-door-saloon.svg',
  }

  const ncdProps = {
    updateState,
    updateDataState,
    ncd,
    NCDStep,
    step,
    other_vehicle_relationship,
    other_vehicle_ncd,
  }

  const quoteProps = {
    reg,
    quoteId,
    promo,
    partner,
    promoAmount,
    step,
    vehicle,
    annualEstimate,
    showModal,
    activeModal,
    pm_rate,
    sub_rate,
    estimated_mileage,
    renewalReminderEmailRegistered,
  }

  return (
    <WidgetStyle step={state.step}>
      <Suspense
        fallback={
          <div className="page-loader">
            <IconLoading />
          </div>
        }
      >
        {state.step === 0 && (
          <FindReg
            regFormProps={regFormProps}
            updateState={updateState}
            handleShowModal={handleShowModal}
            handleRegSubmit={handleRegSubmit}
            updateDataState={updateDataState}
            showModal={showModal}
            activeModal={activeModal}
          />
        )}
        {state.step === 1 && state.data && state.data.vehicle && <Mileage mileageProps={mileageProps} />}
        {(state.step === 2 || state.step === 3) && state.data && state.data.vehicle && <NCD ncdProps={ncdProps} />}
        {state.step === 4 && state.data && state.data.vehicle && (
          <Age
            updateState={updateState}
            updateDataState={updateDataState}
            step={step}
            multipleAges={multipleAges}
            ages={ages}
          />
        )}
        {state.step === 5 && state.data && state.data.vehicle && (
          <Postcode
            updateState={updateState}
            updatePostcode={updatePostcode}
            step={step}
            postcodeValid={postcodeValid}
            postcode={postcode}
            fetching={fetching}
          />
        )}
        {state.step === 6 && state.data && state.data.vehicle && (
          <RenewalMonth
            handleQuickQuoteSubmission={handleQuickQuoteSubmission}
            updateDataState={updateDataState}
            renewal_month={renewal_month}
            fetching={fetching}
          />
        )}
        {state.step === 7 && state.data && state.data.vehicle && (
          <Quote
            config={config}
            updateState={updateState}
            updateMileage={updateMileage}
            handleShowModal={handleShowModal}
            handleSendRenewalEmail={handleSendRenewalEmail}
            calculateAnnualEstimate={calculateAnnualEstimate}
            quoteState={quoteProps}
          />
        )}
        {state.step === 11 && (
          <Decline
            declinePlus={declinePlus}
            letMeKnowSent={letMeKnowSent}
            handleRegEmailOnDecline={handleRegEmailOnDecline}
          />
        )}
      </Suspense>

      <WidgetFooter />

      {(state.step > 0 && state.step < 7) || state.step === 11 ? (
        <ButtonPrevious
          className="widget-previous-button"
          label={getPreviousButtonText(state.MOTStep, state.step, state.isMOTFlow)}
          onClick={() => {
            if (state.MOTStep === 1 && state.step === 1 && state.isMOTFlow) {
              navigate({
                pathname: '/mot',
                search: `${state.analyticsEnabled ? '?analytics=on' : ''}`,
              })
            } else {
              updateState({
                stateName: 'step',
                value: state.step < 11 ? state.step - 1 : 6,
              })
            }
          }}
        />
      ) : null}
    </WidgetStyle>
  )
}
export default Widget
