import styled from 'styled-components'
import { theme } from '@by-miles/bymiles-styles'

export const WidgetStyle = styled.div<{ step: number }>`
  width: 100%;
  min-height: 580px;
  padding: ${theme.grid.spacing.s}px;
  background: ${props => (props.step === 0 ? `url('./widget-background.png') center bottom no-repeat` : 'none')};
  background-size: 828px 169px;
  position: relative;
  .logo-wrapper {
    position: absolute;
    bottom: ${theme.grid.spacing.s}px;
    left: 0;
    width: 100%;
    height: 24px;

    @media (max-width: 400px) {
      bottom: ${({ step }) => (step === 0 ? `${theme.grid.spacing.md}px` : `${theme.grid.spacing.s}px`)};
    }

    .widget-logo {
      width: 130px;
      height: 24px;
      position: absolute;
      left: ${theme.grid.spacing.s}px;
      svg {
        width: 100%;
        height: 100%;
      }
    }
    .legal-caption {
      position: relative;
      width: ${theme.grid.resolution.mobile.container}px;
      text-align: center;
      margin: auto;
      color: ${theme.primaryColours.aubergine.main};
      p {
        line-height: 16px;
        color: ${theme.primaryColours.aubergine.main}!important;
      }
      @media (max-width: 607px) {
        position: absolute;
        left: unset;
        right: ${theme.grid.spacing.xs}px;
        width: 60%;
      }
    }
  }

  .widget-previous-button {
    position: absolute !important;
    top: ${theme.grid.spacing.s}px;
    left: ${theme.grid.spacing.s}px;
    background-color: ${theme.tints.aubergine}!important;
  }

  .links-wrapper {
    width: 360px;
    max-width: 100%;
    margin: ${theme.grid.spacing.s}px auto ${theme.grid.spacing.md}px;
    padding-left: 18px;
    text-transform: uppercase;
    a {
      color: ${theme.primaryColours.aubergine.main};
      &:first-child {
        margin-right: 16px;
      }
    }
  }

  .info-icon-with-text {
    margin-top: 8px;
    display: flex;
    gap: 8px;
    align-items: center;
    color: ${theme.primaryColours.aubergine.main};
    .info_icon {
      cursor: pointer;
      height: 20px;
    }
  }

  .page-loader {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 600px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`
