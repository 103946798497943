import React, { useContext } from 'react'
import { ProviderContext } from '../Provider'
import { Logo, AnchorFooterLink, Caption } from '@by-miles/bymiles-styles'
import { getUrl } from '../utils/widgetHelpers'

export const WidgetFooter = () => {
  const {
    state: { step, urlParams },
  } = useContext(ProviderContext)
  const url = getUrl(urlParams)
  return (
    <>
      {step < 1 && url && (
        <div className="links-wrapper">
          <a
            href={`https://www.bymiles.co.uk/terms-of-business${url.replace(/\s/g, '')}`}
            target="_blank"
            rel="noreferrer"
          >
            <AnchorFooterLink>Terms of business</AnchorFooterLink>
          </a>
          <a
            href={`https://www.bymiles.co.uk/privacy-notice${url.replace(/\s/g, '')}`}
            target="_blank"
            rel="noreferrer"
          >
            <AnchorFooterLink>Privacy notice</AnchorFooterLink>
          </a>
        </div>
      )}

      <div className="logo-wrapper">
        <Logo className="widget-logo" />
        {step === 0 && (
          <div className="legal-caption">
            <Caption>Authorised and regulated by the Financial Conduct Authority (FCA) number 773046.</Caption>
          </div>
        )}
      </div>
    </>
  )
}
