import queryString from 'query-string'
import { processCookies } from '@by-miles/bymiles-cookie-monster/middleware'
import Cookie from 'js-cookie'
import config from './config'
import ms from 'ms'

const urlParams = queryString.parse(location.search)
urlParams.ppp = urlParams.ots = urlParams.ref = urlParams.pap = urlParams.partner
urlParams.dtl = 'widget'
urlParams.sid = 'quick-quote'

const { clearCookies, setCookies } = processCookies({
  cookies: Cookie.get(),
  query: urlParams,
})
clearCookies.forEach(cookieName => Cookie.remove(cookieName))
setCookies.forEach(cookie =>
  Cookie.set(cookie.cookieName, cookie.value, {
    ...cookie,
    domain: config.strippedDomain,
    sameSite: 'None',
    secure: true,
    expires: ms(cookie.duration) / (1000 * 60 * 60 * 24),
  }),
)

export const cookies = setCookies.reduce((map, obj) => {
  map[obj.cookieName] = obj
  return map
}, {})
