import React, { useContext } from 'react'
import { ProviderContext } from '../Provider'

export const PartnerTracking = () => {
  const { state } = useContext(ProviderContext)
  return state.analyticsEnabled && state.urlParams.partner ? (
    <img
      src={`https://partners.bymiles.co.uk/scripts/nlq70kbau?pap=${state.urlParams.partner}&amp;pap-b=fff1d4b1&amp;w=1&refx2s6d=`}
      width="1"
      height="1"
      alt=""
    />
  ) : (
    <></>
  )
}
