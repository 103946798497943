import React, { Component, createContext } from 'react'
import config from './config'
import queryString from 'query-string'
import Cookie from 'js-cookie'
import { getApiKey } from './utils/getAPIKey'
import { quickQuoteAPI } from './utils/quickQuoteAPI'
import { cookies } from './cookieMonster'
import { postcodeRegex, regPattern } from './utils/regex'
import { DataLayer } from '@by-miles/data-layer'

export const ProviderContext = createContext()
export class Provider extends Component {
  constructor(props) {
    super(props)

    const urlParams = queryString.parse(location.search)

    this.state = {
      step: 0,
      regErrorMessage: '',
      regError: false,
      showModal: false,
      updateMileage: false,
      data: {
        ncd: null,
        reg: null,
        ages: [],
        postcode: null,
        vehicle: {},
        key: null,
        additionals: [null, null, null],
        other_vehicle_relationship: null,
        other_vehicle_ncd: null,
        renewal_month: null,
      },
      promo: {
        amount: '',
        code: '',
        created_at: '',
        description: '',
        expiry_date: '',
        id: 0,
        isValid: false,
        type: '',
        updated_at: '',
      },
      mot: {
        motExpiry: '',
        taxExpiry: '',
        taxStatus: '',
      },
      immobiliser: {},
      pricing: {},
      error: null,
      fetching: false,
      NCDStep: 0,
      multipleAges: false,
      urlParams,
      declinePlus: false,
      letMeKnowSent: false,
      renewalReminderEmailRegistered: false,
      analyticsEnabled: urlParams.analytics === 'on',
      isMOTFlow: window.location.href.includes('/mot'),
      MOTStep: 0,
    }

    Object.assign(this, config)

    this.domain = this.quoteFlowAPIPrefix
    this.postCodeEndPoint = `${this.quoteFlowAPIPrefix}/v1/address`
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevState.step !== this.state.step) {
      // step 3 is the extra 0 ncd questions. But if they go back from the age page (step 4), they need to go back to step 2 (the original NCD question)
      if (this.state.step === 3 && prevState.step === 4) {
        this.setState({
          step: 2,
          NCDStep: 0,
        })
      }

      if (this.state.step === 2 && prevState.step === 3) {
        this.setState({
          NCDStep: 0,
        })
      }
    }
  }

  calculateAnnualEstimate = (perMilesRate, totalUpFrontCost, estimatedMileage, promo) => {
    const netMileageCost = Math.max(Number(estimatedMileage) * Number(perMilesRate / 100) - (promo || 0), 0)
    return (netMileageCost + Number(totalUpFrontCost)).toFixed(2)
  }

  calculateMonthly = (estimatedMileage, perMileRate) => {
    return (Number(estimatedMileage) * (Number(perMileRate) / 100)) / 12
  }

  updateMileage = estimatedMileage => {
    if (estimatedMileage === null) return false
    const update = {
      data: {
        ...this.state.data,
        estimated_mileage: parseFloat(estimatedMileage),
      },
    }
    if (this.state.pricing.pm_rate && this.state.pricing.sub_rate) {
      update.pricing = {
        ...this.state.pricing,
        annualEstimate: this.calculateAnnualEstimate(
          this.state.pricing.pm_rate,
          this.state.pricing.sub_rate,
          estimatedMileage,
          parseInt(this.state.promo.amount) || 0,
        ),
        monthly: this.calculateMonthly(estimatedMileage, this.state.pricing.pm_rate),
      }
    }
    this.setState(update)
  }

  updateState = ({ stateName, value }) => {
    if (!stateName && !value) return false
    this.setState({ [stateName]: value })
  }

  updateDataState = ({ stateName, value }) => {
    if (!stateName && !value) return false
    this.setState({
      data: {
        ...this.state.data,
        [stateName]: value,
      },
    })
  }

  updatePostcode = async postcode => {
    if (postcode === this.state.postcode) return
    this.setState({
      postcodeValid: postcode.match(postcodeRegex) && (await this.postcodeCheck(postcode)),
      data: {
        ...this.state.data,
        postcode: postcode.replace(/\s/g, '').toUpperCase(),
      },
    })
  }

  handleShowModal = activeModal => {
    this.setState({ showModal: true, activeModal })
    DataLayer.customEvent({
      eventName: `qq widget - info modal ${activeModal}`,
      eventType: 'form tracking',
      section: 'qq widget',
    })
  }

  postcodeCheck = postcode => {
    this.setState({ fetching: true })
    return fetch(`${config.quoteFlowAPI}/v1/address/${postcode}`, {
      method: 'GET',
      headers: {
        'x-api-key': getApiKey(),
      },
    })
      .then(response => !!response.ok)
      .finally(() => this.setState({ fetching: false }))
  }

  handleRegSubmit = event => {
    event.preventDefault()
    this.setState({ fetching: true, regErrorMessage: '' })
    if (!this.state.data.reg || !this.state.data.reg.match(regPattern)) {
      this.setState({
        regErrorMessage: "Sorry, this doesn't look like a reg number.",
        fetching: false,
      })
      const section = this.state.isMOTFlow ? 'mot' : 'qq'
      DataLayer.customEvent({
        eventName: `${section} widget - form error`,
        eventType: 'form error',
        formField: 'find reg',
        formMessage: 'invalid reg',
        section: `${section} widget`,
      })
      return false
    }

    quickQuoteAPI(`/v1/vehicle/${this.state.data.reg}`)
      .then(async response => {
        const data = await response.json()
        this.setState({ fetching: false })
        const section = this.state.isMOTFlow ? 'mot' : 'qq'
        DataLayer.customEvent({
          eventName: `${section} widget - reg submit success`,
          eventType: 'form tracking',
          section: `${section} widget`,
        })
        if (response.status < 400) {
          if (this.state.urlParams.promo) {
            this.getPromoDetails()
          }
          const normalised = data.normalised
          this.setState({
            step: 1,
            MOTStep: 1,
            data: {
              ...this.state.data,
              estimated_mileage: normalised.mileage || 0,
            },
            vehicle: {
              ...this.state.vehicle,
              engine: normalised.size,
              estimated_mileage: normalised.mileage || 0,
              hasEditedMileage: false,
              make: normalised.make,
              model: normalised.model,
              bymiles_make: normalised.bymiles_make,
              bymiles_model: normalised.bymiles_model,
            },
            mot: {
              motExpiry: normalised.mot_expiry_date,
              taxExpiry: normalised.tax_due_date,
              taxStatus: normalised.tax_status,
            },
          })
        } else {
          this.setState({
            regErrorMessage: "Sorry, we can't find that car. Please check your registration plate and try again.",
            fetching: false,
          })
          const section = this.state.isMOTFlow ? 'mot' : 'qq'
          DataLayer.customEvent({
            eventName: `${section} widget - form error`,
            eventType: 'form error',
            formField: 'find reg',
            formMessage: 'unrecognized reg',
            section: `${section} widget`,
          })
          return false
        }
      })
      .catch(error => {
        console.log(error)
      })
  }

  getPromoDetails = () => {
    const trackingCookie = Cookie.get('BM-ref-tracking')
    quickQuoteAPI('/v1/validate-promo', {
      method: 'POST',
      body: {
        promo: (this.state.urlParams.promo || '').toUpperCase(),
        hasPPP: !!Cookie.get('BM-ppp-tracking'),
        hasRAF: !!(trackingCookie && trackingCookie.includes('refer-a-friend')),
      },
    })
      .then(async response => {
        const promo = await response.json()
        if (response.status < 400) {
          this.setState({ promo })
        } else {
          return false
        }
      })
      .catch(error => {
        console.log(error)
      })
  }

  handleQuickQuoteSubmission = () => {
    this.setState({ fetching: true })
    const promo = this.state.promo?.code

    quickQuoteAPI('/v1/quick-quote', {
      method: 'POST',
      body: {
        ...this.state.data,
        ...(promo && { promo }),
        ppp: (cookies['BM-ppp-tracking'] || {}).value,
        dtl: (cookies['BM-dtl-tracking'] || {}).value,
        ots: (cookies['BM-ots-tracking'] || {}).value,
        ref: (cookies['BM-ref-tracking'] || {}).value,
      },
    })
      .then(async response => {
        const data = await response.json()
        const pricing = data.pricing.pricing
        if (data.is_valid) {
          this.setState({
            quoteId: data.id,
            pricing: {
              ...pricing,
              annualEstimate: this.calculateAnnualEstimate(
                pricing.pm_rate,
                pricing.sub_rate,
                this.state.data.estimated_mileage,
                parseInt(this.state.promo.amount) || 0,
              ),
              monthly: this.calculateMonthly(this.state.data.estimated_mileage, pricing.pm_rate),
            },
            step: this.state.step + 1,
            fetching: false,
          })
        } else {
          this.setState({ step: 11, fetching: false })
        }
      })
      .catch(error => {
        console.log('an error posting to quick quote endpoint', error)
      })
  }

  handleRegEmailOnDecline = email => {
    fetch('/v1/signup', {
      method: 'POST',
      body: JSON.stringify({
        group: 'qqRejection',
        data: {
          email,
          source: 'QuickQuote',
          quote_id: this.state.quoteId || 'unknown',
          partner: this.state.urlParams.partner,
        },
      }),
    })
      .then(() => this.setState({ letMeKnowSent: true }))
      .catch(() => {})
  }

  handleSendRenewalEmail = email => {
    fetch('/v1/signup', {
      method: 'POST',
      body: JSON.stringify({
        group: 'renewalReminder',
        data: {
          email,
          source: 'QuickQuote',
          quote_id: this.state.quoteId || 'unknown',
          partner: this.state.urlParams.partner,
          renewal_month: this.state.data.renewal_month,
        },
      }),
    })
      .then(() => this.setState({ renewalReminderEmailRegistered: true }))
      .catch(() => {})
  }

  render() {
    return <ProviderContext.Provider value={{ ...this }}>{this.props.children}</ProviderContext.Provider>
  }
}
