import './App.scss'
import React from 'react'
import './cookieMonster'
import { Route, Routes, BrowserRouter as Router } from 'react-router-dom'
import { Provider } from './Provider'
import { Head } from './components/Head'
import { PartnerTracking } from './components/PartnerTracking'
import Widget from './pages/Widget'
import MOT from './pages/MOT'
import { MagazineWidget } from './components/MagazineWidget'
function App() {
  return (
    <Router>
      <Provider>
        <Routes>
          {['/', `/${process.env.REACT_APP_JIRA_REF || 'main'}/index.html`].map(path => (
            <Route
              path={path}
              element={
                <>
                  <Head />
                  <PartnerTracking />
                  <Widget />
                </>
              }
            />
          ))}
          <Route path="/magazine" element={<MagazineWidget />} />
          <Route
            path="/mot"
            element={
              <>
                <Head />
                <PartnerTracking />
                <MOT />
              </>
            }
          />
        </Routes>
      </Provider>
    </Router>
  )
}

export default App
