import React, { useContext } from 'react'
import Helmet from 'react-helmet'
import { ProviderContext } from '../Provider'
import { GTMScript } from '../GMTScript'

export const Head = () => {
  const { state } = useContext(ProviderContext)
  return (
    <Helmet titleTemplate="%s | By Miles" defaultTitle="By Miles">
      {state.analyticsEnabled && <script src={GTMScript()} type="text/javascript" />}
    </Helmet>
  )
}
